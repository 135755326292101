<template>
  <div class="t-e-switch-language">
    <ClientOnly>
      <el-dropdown ref="lDropdown" trigger="hover" popper-class="t-e-switch_dropdown" @visible-change="dropdownClick">
        <div class="t-e-switch-language-c">
          <LanguageLogo class="lg" />
          <span class="c-text">{{ language_text }}</span>
          <el-icon :class="[arrowIconActive ? 'arrow-icon-active' : '']"><CaretBottom /></el-icon>
        </div>
        <template #dropdown>
          <ClientOnly>
            <div class="language-list-wrapper">
              <div
                v-for="lItem in Global_Language"
                :key="lItem.value"
                :class="['l-item', language_text === lItem.name ? 'l-item-active' : '']"
                @click="languageClick(lItem)"
              >
                <!-- <img
                :class="[language_text === lItem.name ? 'icon-active' : '', 'icon']"
                src="@/assets/icons/common/success-icon.png"
                alt=""
              /> -->
                <span class="l-text">{{ lItem.name }}</span>
              </div>
            </div>
          </ClientOnly>
        </template>
      </el-dropdown>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { Global_Language, setGlobalLanguage } from "@/constant/i18n-country"
import { CaretBottom } from "@element-plus/icons-vue"
import type { DropdownInstance } from "element-plus"
import LanguageLogo from "@/assets/icons/common/language-logo.svg"

const lDropdown = ref<DropdownInstance>()
let arrowIconActive = ref(false)

function handleDropVisible(visible: boolean) {
  if (!lDropdown.value) return
  if (visible) {
    lDropdown.value.handleClose()
  } else {
    lDropdown.value.handleOpen()
  }
}

function languageClick(lItem: any) {
  handleDropVisible(true)
  document.documentElement.scrollTop = 0
  setGlobalLanguage(lItem.value)
}

const dropdownClick = (visible) => {
  arrowIconActive.value = visible
}

let language_text = computed(() => {
  return Global_Language.find((l) => l.value === useI18nCountry().value)?.name || Global_Language[0].name
})
</script>

<style lang="scss">
.t-e-switch-language {
  cursor: pointer;
  :deep(.el-dropdown) {
    outline: none;
  }
  .t-e-switch-language-c {
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    outline: none;
    :deep(.el-icon) {
      svg {
        transition: transform linear 0.2s;
      }
      &.arrow-icon-active {
        svg {
          transform: rotate(180deg);
        }
      }
    }
    .lg {
      width: 16px;
      height: 16px;
    }
    .c-text {
      margin: 0 4px;
    }
  }
}
</style>
<style lang="scss">
.el-dropdown__popper.t-e-switch_dropdown {
  border-radius: 10px;
  background: #fff;
  box-shadow: none;
  border: none;
  .el-scrollbar {
    border-radius: 10px;
  }
  .el-popper__arrow {
    display: none;
  }
  .language-list-wrapper {
    padding: 16px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    .l-item {
      display: flex;
      align-items: center;
      padding: 12px 16px;
      border-radius: 8px;
      min-width: 230px;
      .icon {
        width: 16px;
        margin-right: 4px;
        opacity: 0;
        &.icon-active {
          opacity: 1;
        }
      }
      &:hover,
      &.l-item-active {
        background: rgba(255, 224, 80, 0.3);
      }

      .l-text {
        color: rgba(0, 0, 0, 0.85);
        font-size: 12px;
      }
    }
  }
}
</style>
